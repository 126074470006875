<template>
  <b-container class="mt-3">
    <h2>Create User</h2>
    <hr class="mt-1" />

    <b-form @submit.prevent="createUser">
      <b-form-group id="emailGroup" label="Email" label-for="emailInput">
        <b-form-input
          id="emailInput"
          v-model="form.email"
          type="email"
          required
          placeholder="Enter email"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="phoneGroup" label-for="phoneInput">
        <template slot="label">Phone number</template>
        <the-mask
          id="phoneInput"
          class="form-control"
          mask="(###) ###-####"
          masked
          required
          numeric
          type="tel"
          placeholder="(###) ###-####"
          v-model="form.phone_number"
        />
      </b-form-group>

      <b-form-group
        id="firstNameGroup"
        label="First name"
        label-for="firstNameInput"
      >
        <b-form-input
          id="firstNameInput"
          v-model="form.first_name"
          required
          placeholder="Enter first name"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="lastNameGroup"
        label="Last name"
        label-for="lastNameInput"
      >
        <b-form-input
          id="lastNameInput"
          v-model="form.last_name"
          required
          placeholder="Enter last name"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="passwordGroup"
        label="Initial password"
        label-for="passwordInput"
        invalid-feedback="Password doesn't meet minimum requirements"
      >
        <b-form-input
          id="passwordInput"
          v-model="form.password"
          :state="passwordState"
          type="password"
          required
          placeholder="Enter password"
        ></b-form-input>
      </b-form-group>

     

      <b-button type="submit" variant="primary" block :disabled="creating"
        >Create User <b-spinner small v-if="creating"
      /></b-button>
    </b-form>
  </b-container>
</template>

<script>
import { TheMask } from "vue-the-mask";

export default {
  components: {
    TheMask,
  },
  data() {
    return {
      creating: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        phone_number: "",
        is_super_admin: true
      },
    };
  },
  computed: {
    passwordState() {
      if (this.form.password.length > 0) {
        let regex = new RegExp("((?=.*\\d)(?=.*[A-Z])(?=.*\\W).{8,})");
        return regex.test(this.form.password);
      } else {
        return null;
      }
    },
  },
  methods: {
    async createUser() {
      this.creating = true;
      //TODO when we put blog in make this optional
      this.form.is_super_admin = true;
      try {
        // create user
        await this.$http.post(
          this.$store.state.apiURL + "/users/admins",
          this.form,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        this.$router.push({ name: "manageAdmins" });
      } catch (error) {
        this.handleError(error);
      }
      this.creating = false;
    },
  },
};
</script>

<style lang="scss">
@import "../../scss/theme.scss"; 
</style>
